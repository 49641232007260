import './table-list-loader.less'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import React, { useState, useEffect, useMemo } from 'react'
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic'

interface IProps {
  showLoader: boolean
  size?: SemanticSIZES
  once?: boolean
  showText?: boolean
  className?: string
}

const LoaderView = (props: IProps): React.ReactElement => {
  const { showLoader = false, size = 'big', className = '', once = false, showText = true } = props

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [timerId, setTimerId] = useState(0)

  // TODO: find better approach to handle first load (ported over from loader.tsx)
  useEffect(() => {
    showLoader && setTimerId(window.setTimeout(() => setIsFirstLoad(false), 5000))
  }, [showLoader])

  useEffect(() => () => clearTimeout(timerId), [timerId])

  const dataTableElement = document.getElementById('data-table-v2')

  const loaderHeight = useMemo(() => {
    if (dataTableElement) {
      const totalPageHeight = window.innerHeight
      const topElementHeight = dataTableElement.getBoundingClientRect().top
      const elementHeight = dataTableElement.clientHeight

      const unUsedHeight = totalPageHeight - topElementHeight - elementHeight

      let loaderHeight = totalPageHeight - topElementHeight

      if (unUsedHeight > 0) {
        loaderHeight = loaderHeight - unUsedHeight
      }

      return loaderHeight
    }

    return window.innerHeight
  }, [dataTableElement?.offsetHeight])

  if (showLoader) {
    return (
      <div className="custom-table-list-loader-container">
        <Dimmer
          active={once ? isFirstLoad : true}
          inverted
          className={`custom-loader custom-table-list-loader ${className}`}
          data-test-id="loader-view"
        >
          <div
            style={{
              height: loaderHeight + 'px',
              position: 'relative'
            }}
          >
            <Loader size={size}>{showText && <FormattedMessage id="loader.text.loading" />}</Loader>
          </div>
        </Dimmer>
      </div>
    )
  } else {
    return <></>
  }
}

export default LoaderView
